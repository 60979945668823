<template>
  <v-btn
    icon
    small
    v-if="consolidable"
    @click="toggleConsolidated"
  >
    <icon-tooltip
      :icon-name="computedConsolidated?'i.Conso':'i.NotConso'"
      :color="computedConsolidatedColor"
      :tooltip-text="computedConsolidated?$t('t.Consolidated'):$t('t.NotConsolidated')"
    />
  </v-btn>
</template>

<script>
export default {
  components: {
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  data () {
    return {
      dataConsolidated: false
    }
  },
  computed: {
    computedConsolidatedColor () {
      return this.computedConsolidated === true ? 'primary' : ''
    },
    computedConsolidated: {
      get () {
        return this.dataConsolidated && this.consolidable
      },
      set (v) {
        if (!this.consolidable || this.readonly) { return }
        this.dataConsolidated = v
        this.$emit('update:consolidated', v)
      }
    }
  },
  methods: {
    toggleConsolidated () {
      if (this.readonly || !this.consolidable) { return }
      this.computedConsolidated = !this.computedConsolidated
    }
  },
  props: {
    consolidated: Boolean,
    consolidable: Boolean,
    readonly: Boolean
  },
  watch: {
    consolidated: {
      immediate: true,
      handler (v) {
        this.dataConsolidated = v === true
      }
    }
  }
}
</script>
